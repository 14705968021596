import { useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import Scrollbar from '../../common/CustomScroll/Scrollbar';
import ChatContainer from '../../common/Container/ChatContainer';
import { MessageRender, TypingContolProvider } from '../../common/Messages';

import { Bot } from '../../services';
import { BotAction } from '../../types';
import { ChatMessage } from '../../services/types';
import { BehaviorSubject } from 'rxjs';
import useAppHeader from '../../common/ChatAppBar/useAppHeader';
import BottomModal from './components/bottom-modal-box/BottomModal';
import useAppBarAction from '../../common/ChatAppBar/useAppBarAction';
import processHeaderActions from './process-header-actions';
import useChatBot from './hooks/useChatBot';
import useChatOnClose from './hooks/useChatOnClose';
import { openModalBox } from './components/bottom-modal-box/useModalBox';
import { useChatOnCallAgent } from './hooks/useOnCallAgent';
import { useSessionStorageState } from 'ahooks';
import ChatStartInfo from './components/ChatStartInfo/ChatStartInfo';
import useUserSession from '../../common/useUserSession/useUserSession';
import { callSupport } from '../../services/mobile-comunications';
import useIdleTimer from '../../common/hooks/use-idle-time/use-idle-time';

const bot = new Bot();
const typingStream$ = new BehaviorSubject<number>(0);

const clickExternalLinkHander = (event: Event) => {
  if (!(event.target instanceof HTMLElement)) {
    return;
  }

  if (event.target.tagName !== 'A') {
    return;
  }
  const target = event.target.closest('a');
  if (target?.hasAttribute('data-external-phone')){
    callSupport();
  }
}

const Chat: React.FC<{}> = () => {
  const ref = useRef<HTMLElement>(null);
  const [chatHistory, setChatHistory] = useSessionStorageState<ChatMessage[]>(
    'chat-history',
    { defaultValue: [] }
  );
  const [typingIndex, setTypingIndex] = useState<number>(0);
  const { userSession } = useUserSession();
  if (!userSession) {
    throw new Error('need to login first');
  }
  const { firstName } = userSession;

  const scrollToBottom = () => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  useAppHeader('Chat');
  useAppBarAction(processHeaderActions);

  const [scrollIntervalTimer, setScrollIntervalTimer] = useState<NodeJS.Timer>();
  useEffect(() => {
    const typingSubscription = typingStream$.subscribe((index) => {
      setTypingIndex(index);
      scrollToBottom();
    });
    return () => {
      typingSubscription.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typingStream$]);

  const onFinishTyping = () => {
    clearInterval(scrollIntervalTimer);
    setTimeout(scrollToBottom, 100);
  }

  useEffect(() => {
    document.addEventListener('click', clickExternalLinkHander);
    return () => {
      document.removeEventListener('click', clickExternalLinkHander);
    }
  }, []);

  const [botReady, historyIndex] = useChatBot(
    bot,
    { firstName, chatHistory: chatHistory ?? [] },
    () => {
      setChatHistory(bot.chatHistory);
    }
  );

  useChatOnClose(bot, () => {
    openModalBox('leave-chat-confirmation');
  });

  useChatOnCallAgent(bot, () => {
    openModalBox('call-agent');
  });

  useIdleTimer({
    timeout: 5000 * 60,
    onIdle: () => {
      openModalBox('close-inactive-chat');
    },
  });

  const onClickAction = (action: BotAction) => {
    const currentMessage = chatHistory
      ? chatHistory[chatHistory.length - 1]
      : null;
    if (!currentMessage || currentMessage.sender !== 'bot') {
      return void 0;
    }
    const selectedAction = currentMessage.actions?.find(
      (a) => a.value === action.value && a.type === action.type
    );
    if (!selectedAction) {
      return void 0;
    }
    bot.sendAction(action);
  };

  const currentMessageTextSize = (chatHistory ?? [])[typingIndex]?.message
    .length;
  let bottomBoxHeight = currentMessageTextSize > 250 ? '100px' : '40px';

  if (!botReady) {
    return null;
  }
  return (
    <Scrollbar>
      <TypingContolProvider typingStream={typingStream$}>
        <ChatContainer>
          <ChatStartInfo />
          {(chatHistory ?? []).map((message, index) => {
            if (index > typingIndex) return null;

            return (
              <MessageRender
                messageIndex={index + 1}
                key={message.id}
                message={message}
                isHtml={message.isHtml}
                onClickAction={onClickAction}
                onFinishTyping={onFinishTyping}
                typingAnimation={index > historyIndex}
              />
            );
          })}
          <Box ref={ref} sx={{ mt: 2, height: bottomBoxHeight }} />
        </ChatContainer>
      </TypingContolProvider>
      <BottomModal />
    </Scrollbar>
  );
};

export default Chat;
